/* https://www.w3schools.com/howto/howto_css_switch.asp*/
/* The switch - the box around the slider */

// vars
// 26, 4 デフォ, 
// 18, 2 ちいさいの
$sw-radius: 26px; //丸の直径
$sw-pad: 4px; //丸と外側とのすき間
$sw-box-w: $sw-pad + $sw-radius + $sw-radius + $sw-pad;
$sw-box-h: $sw-pad + $sw-radius + $sw-pad;
$sw-box-border: $sw-box-h;
// border-radiusがよくわかってない。
$sw-border-radius: $sw-box-h;


.switch {
  position: relative;
  display: inline-block;
  width: $sw-box-w;
  height: $sw-box-h;

  /* オリジナルのinput要素は隠しておく */
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* |●  | or |  ●| */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:silver; /* スイッチオフ時の背景色 */
  //-webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: $sw-radius;//26px;
    width: $sw-radius;//26px;
    left: $sw-pad; //4px;
    bottom: $sw-pad; //4px;
    background-color: white; /* スイッチの●の色 */
    //-webkit-transition: .4s;
    transition: .4s;
  }

  &.round {
    border-radius: $sw-border-radius;
  }
  &.round:before {
    border-radius: 50%;
  }
}

input {
  
  &:checked + .slider {
    background-color: dodgerblue; /* スイッチオン時の背景色*/
  }
  &:focus + .slider {
    box-shadow: 0 0 1px dodgerblue;
  }
  &:checked + .slider:before {
    transform: translateX($sw-radius); /* スイッチオン時の●の右への移動 */
  }
}

