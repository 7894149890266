//
// tableを見やすく色分け、する。
//
// tr:nth-child(odd) は inline style できないので

.head {
  font-size: 11pt;
  font-weight: bold;
  tr {
    background-color: midnightblue;
    color: white;
  }
}

.userinfo {
  font-size: 10pt;
  tr {
    background-color: azure;
    color: midnightblue;
  }
  tr:nth-child(odd) {
    background-color:lightyellow;
    color: midnightblue;
  }
}

