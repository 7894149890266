
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input[type="checkbox"]{
  transform: scale(2.1);
}
