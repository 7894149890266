// header.scss
//
// hoverなど 疑似要素が必要なものは style objectでは表現できないので cssにて。
//
// justify-contentについて...
/*
長さや自動マージンが適用された後に配置が行われることから、フレックスボックスレイアウトで
 flex-grow が 0 ではないフレックス要素が少なくとも 1 つある場合は利用可能なスペースが
 なくなりますので、このプロパティの効果はないでしょう。
*/

// textAlign: "right" とか "end" がtypescriptエラーなので。.scssでやる。
 
//

$header-height: 52px;
$header-color-bg: rgb(0, 0, 64);
$header-color: white;
$header-item-hover-color: orange;
$header-item-active-color: orangered;

// Header内のアイテム並びなどを stickyで flexにする。
header {
  position: sticky;
  top: 0px;
  z-index: 10;
  //  
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
  //
  height: 52px;
  background-color: $header-color-bg;
  opacity: 1.0;
  //
  // ロゴ、ボタンなど共通部
  //
  .base-item {
    height: $header-height;
    background-color: transparent;
    color: $header-color;
    margin: auto;
    padding: 1%; // iPhoneでは間が空きすぎるので。
  }
  //
  // 詰め物 (ロゴを左端に、他を右端に、寄せるため)
  //
  & .expander {
    @extend .base-item;
    flex-grow: 1;
    border: 2px solid transparent;
    font-size: 9pt;
    line-height: 1.2;
    //
    &:hover{
      cursor: pointer;
      border-top: 2px solid transparent;
    }

  }
  //
  // ロゴ
  //
  & .logo {
    @extend .base-item;
    cursor: pointer;
    border: 4px solid transparent;
    border-radius: 24px;
    cursor: pointer;
    //
    &:hover {
      border: 4px solid white;
    }
    &:active {
      border: 4px solid $header-item-active-color;    
    }
  }
  //
  // bars (button)
  //
  & .bars {
    @extend .base-item;
    border: 2px solid transparent;
    //
    &:hover {
      color: $header-item-hover-color;
      border: 2px solid transparent;
    }
    &:active {
      color: $header-item-active-color;
      border: 2px solid transparent;
    }
  }
  //
  // ghost (button)
  //
  & .ghost {
    @extend .base-item;
    border: 2px solid transparent;
    //
    &:hover {
      border-top: 2px solid $header-item-hover-color;
    }
    &:active {
      border-top: 2px solid $header-item-active-color;
    }
  }
  //
  // anchor
  //
  & .anchor {
    @extend .base-item;
    cursor: pointer;
    outline: none;
    border: 2px solid transparent;
    //
    & .a-text {
      display: flex;
      flex-direction: column;
      align-items: flex-end; // endではなくflex-endがサポートされているよ、となるOK
      font-size: 10pt;
      //justify-content: center; //効かない ので -1,-2, -3をつくる。??
      //margin: auto; //??これもたぶん同じ理由で効かないのかな。

      &.line-1 {
        padding: 0.6rem 0px; //上下、左右
      }
      &.line-2 {
        padding: 0.2rem 0px; //上下、左右
        line-height: 1.2;
      }
      &.line-3 {
        line-height: 1.0;
      }

      //text-align: end;//flex-box出ないときに使う。
    }
    //
    // L, V, H, A
    //
    &:link {
      text-decoration: none;
      outline: none;
    }
    &:visited {
      text-decoration: none;
      outline: none;
    }
    &:hover {
      text-decoration: none;
      outline: none;
      border-top: 2px solid $header-item-hover-color;
    }
    &:active {
      text-decoration: none;
      outline: none;
      border-top: 2px solid $header-item-active-color;
    }
    //
  }

}
