//
// calendar.scsss
//


.calcontainer {
  display: flex;
}

.colbox{
  width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected{
  color: crimson;
}
.box {
  border: 2px;
  border-style: solid;
  border-color: whitesmoke;
  border-top: transparent;
  border-left: transparent;
}
.wraptext{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.line0{
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  background-color: whitesmoke;
}
.sunday{
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  color: orangered;
}
.saturday{
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  color: blue;
}
.today {
  font-size: 1em;
  font-weight: bold;
  text-align: right;
  background-color: white;
  cursor: pointer;
}
.line1 {
  font-size: 0.9em;
  font-weight: normal;
  text-align: right;
  background-color: white;
  cursor: pointer;
}
.line2 {
  font-size: 0.8em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.line3 {
  font-size: 0.8em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.line4 {
  font-size: 0.2em;
  height: 12px;
  background-color: whitesmoke;
  cursor: pointer;
}
.wrapp {
  display: flex;
  flex-direction: column;
}
.prevMonth {
  color: grey;
}
.nextMonth {
  color: grey;
}
.passedby {
  color: gray;
}