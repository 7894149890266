/* scssでは ; は省略できない。 */

// design debug
.pinkborder {
  border: 1px;
  border-style: solid;
  border-color: #FFC0CB;
}
.blueborder {
  border: 1px;
  border-style: solid;
  border-color: blue;
}
.darkborder {
  border: 1px;
  border-style: solid;
  border-color: darkgrey;
}
.greyborder {
  border: 1px;
  border-style: solid;
  border-color: lightgrey;
}


//バーガーアイコンの枠を消す
.navbar-toggler{
  border: none;
}
.navbar-toggler:active{
  outline: none;
}
button:focus{
  outline: none;
}

// password reveal
.password-reveal {
  left: auto;
  position: absolute;
  right: 8px;
  top: 8px;
}


// label input などでカーソルをポインターに。
.pointer {
  cursor: pointer
}

// marginなど spacingについては https://getbootstrap.jp/docs/5.0/utilities/spacing/ 

//--バーガーアイコンはこうやるのかでも fontawesomeにあとでしよう。
//.navbar-light .navbar-toggler-icon {
//  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
//}

// navbarの link色などを少しきつくする。
//---https://www.codeply.com/go/sGB65XJR63/bootstrap-4-sass-navbar-customize
//
$navbar-dark-color: #FFFFFF;
$navbar-dark-hover-color: #FF69B4;// hotpink
$navbar-dark-active-color: #FF4540;

//
// container-fluidに style={{padding: 0}} をいれて 画面を広くした。スマホ優先。
// 正しいやりかたが？なので app.tsxの container-fluidのところ、また
// calendar.tsxの container-fluidのところをいくつか。 chromeの debugで computedされた 値をみながら....sigh

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";

